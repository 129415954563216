<template>
  <div class="login">
    <div class="top">
      <img src="@/assets/img/toppic.png" alt="" />
    </div>
    <div class="loginform">
      <div class="logobox">
        <img src="@/assets/img/logo.png" alt="" />
        <span>SAAS管理系统</span>
      </div>
      <div class="input">
        <input type="text" name="" v-model="account" placeholder="请输入帐号" />
      </div>
      <div class="input">
        <input type="password" name="" v-model="password" placeholder="请输入密码" />
      </div>
      <button type="button" class="login_bt" @click="toLogin">登入</button>
      <button type="button" class="wx_bt" @click="wxLogin">微信登录</button>
    </div>
  </div>
</template>

<script setup>
import { ref,toRefs } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import API from "@/axios";
import mitts from "@/bus";
const router = useRouter();

const account = ref("");
const password = ref("");

function toLogin() {
  API({
    url: "/web/login.html",
    method: "post",
    data:{account:account.value,password:password.value}
  }).then((res) => {
    Toast.success(res.data.msg);
    if(res.data.status == 200){
      setTimeout(function(){
        mitts.emit("showLoading");
        router.push({
          // path: "/count/myHome",
          path: "/enter/h5Home",
        });
      }, 2000);
    }
  });
}

function wxLogin(){
  API({
    url:"/web/wxLoginWap",
    method:"post",
    // 'data':{}
  }).then((res)=>{
    Toast.success(res.data.msg);
    if(res.data.status == 200){
      window.location.href = res.data.data.url;
    }
  })
}

toRefs(Toast,API);
</script>

<style lang="less" scoped>
.login {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .top {
    width: 100%;
    img {
      width: 100%;
      display: block;
    }
    margin-bottom: 10px;
  }
}
.loginform {
  overflow: hidden;
  width: 77%;
  margin: 0 auto;
  position: fixed;
  align-items: center;
  padding: 11%;
  bottom: 50px;
  .logobox {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    img {
      display: block;
      width: 80px;
      margin-right: 20px;
    }
    span {
      font-size: @title-size;
      font-weight: bolder;
      font-family: "Regular";
      color: @font-color;
    }
  }
  .input {
    display: flex;
    align-items: center;
    padding: 28px 0;
    border-bottom: 1px solid @font-third-color;
    margin-bottom: 20px;
    span {
      font-size: @subtitle-size;
      color: @font-color;
      margin-right: 20px;
      font-weight: bold;
    }
    input {
      flex: 1;
      font-size: @subtitle-size;
      color: @font-secondary-color;
      outline: none;
    }
  }
  .input:last-of-type {
    margin-bottom: 90px;
  }

  .login_bt {
    width: 100%;
    height: 98px;
    outline: none;
    background: @theme-secondary-color;
    color: #fff;
    font-size: @subtitle-size;
    font-family: "Bold";
  }
  .wx_bt {
    width: 100%;
    height: 98px;
    outline: none;
    margin-top: 20px;
    background: #26832c;
    color: #fff;
    font-size: @subtitle-size;
    font-family: "Bold";
  }
}
</style>
